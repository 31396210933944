.App {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin: 20px auto;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.image-grid img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.image-grid img.selected {
  opacity: 0.6;
}

.letter-inputs {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 20px;
}

.letter-inputs input {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

.birthday-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-card {
  width: 80%;
  margin: 10px auto;
  font-size: 24px;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.success, .wait {
  animation: fadeIn 0.5s ease-in-out;
}